<template>
  <!--begin::Basic info-->
  <div>
    <div class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Profile Details</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Content-->
      <div id="kt_account_profile_details" class="collapse show">
        <!--begin::Form-->
        <Form id="kt_account_profile_details_form" class="form" novalidate="novalidate" @submit="saveUserInfo()" :validation-schema="profileDetailsValidator">
          <!--begin::Card body-->
          <div class="card-body border-top p-9">
            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6">Profile Image</label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8">
                <!--begin::Image input-->
                <div class="image-input image-input-outline" data-kt-image-input="true" style="background-image: url(/media/avatars/blank.png)">
                  <!--begin::Preview existing avatar-->
                  <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${imageSrcPath})`"></div>
                  <!--end::Preview existing avatar-->

                  <!--begin::Label-->
                  <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                    <i class="bi bi-pencil-fill fs-7"></i>

                    <!--begin::Inputs-->
                    <input type="file" name="avatar" accept=".png, .jpg, .jpeg" @change="onFileChange" />
                    <input type="hidden" name="avatar_remove" />
                    <!--end::Inputs-->
                  </label>
                  <!--end::Label-->

                  <!--begin::Remove-->
                  <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" @click="removeImage()" title="Remove avatar">
                    <i class="bi bi-x fs-2"></i>
                  </span>
                  <!--end::Remove-->
                </div>
                <!--end::Image input-->

                <!--begin::Hint-->
                <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
                <!--end::Hint-->
              </div>
              <!--end::Col-->

            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label required fw-bold fs-6">Full Name</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8">
                <!--begin::Row-->
                <div class="row">
                  <!--begin::Col-->
                  <div class="col-lg-12 fv-row">
                    <Field type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First name" v-model="formData.name" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="name" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row mb-6">
              <!--begin::Label-->
              <label class="col-lg-4 col-form-label fw-bold fs-6">
                <span>Email</span>
              </label>
              <!--end::Label-->

              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <Field type="email" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Email Address" name="email" v-model="formData.email" :disabled="true" />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="email" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
          </div>
          <!--end::Card body-->

          <!--begin::Actions-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" ref="submitButton1" class=" btn btn-primary me-2" :disabled="loading">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Basic info-->
    <!--begin::Sign-in Method-->
    <div class="card mb-5 mb-xl-10">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Change Password</h3>
        </div>
      </div>
      <!--end::Card header-->

      <!--begin::Content-->
      <div id="kt_account_signin_method" class="collapse show">
        <!--begin::Card body-->
        <div class="card-body border-top p-9">

          <!--begin::Password-->
          <div class="d-flex flex-wrap align-items-center mb-8">
            <div id="kt_signin_password_edit" class="flex-row-fluid">
              <div class="fs-6 fw-bold text-gray-600 mb-4">
                Password contain must be at least 8 character, one Uppercase / Lowercase letters, one number and one special char
              </div>

              <!--begin::Form-->
              <Form id="kt_signin_change_password" class="form" novalidate="novalidate" @submit="updatePassword()" :validation-schema="changePassword">
                <div class="row mb-6">
                  <div class="col-lg-4">
                    <div class="fv-row mb-0">
                      <label for="currentPassword" class="form-label fs-6 fw-bolder mb-3">Current Password</label>
                      <Field type="password" class="form-control form-control-lg form-control-solid fw-bold fs-6" name="currentPassword" id="currentPassword" v-model="passwordData.currentPassword" />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="currentPassword" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="fv-row mb-0">
                      <label for="newPassword" class="form-label fs-6 fw-bolder mb-3">New Password</label>
                      <Field type="password" class="form-control form-control-lg form-control-solid fw-bold fs-6" name="newPassword" id="newPassword" v-model="passwordData.newPassword" />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="newPassword" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="fv-row mb-0">
                      <label for="confirmPassword" class="form-label fs-6 fw-bolder mb-3">Confirm New Password</label>
                      <Field type="password" class="form-control form-control-lg form-control-solid fw-bold fs-6" name="confirmPassword" id="confirmPassword" v-model="passwordData.confirmPassword" />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="confirmPassword" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button id="kt_password_submit" type="submit" ref="updatePasswordButton" class="btn btn-primary me-2 px-6">
                    <span class="indicator-label"> Update Password </span>
                    <span class="indicator-progress">
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </Form>
              <!--end::Form-->
            </div>
          </div>
          <!--end::Password-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Sign-in Method-->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from "vue";

import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { ElNotification } from "element-plus";
import globalConstant from "@/core/data/globalConstant.js";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import useEventBus from "../../composable/useEventBus";
import JwtService from "@/core/services/JwtService";
import { formatText } from "@/core/helpers/common";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const route = useRoute();
    const { emitEvent } = useEventBus();
    const submitButton1 = ref(null);
    const updatePasswordButton = ref(null);

    const formData = reactive({
      imagePath: "",
      name: "",
      oldImagePath: "",
    });

    const passwordData = reactive({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    const router = useRouter();
    const fullscreenLoading = ref(false);
    const constants = globalConstant;
    const loading = ref(false);
    const changePasswordLoading = ref(false);
    const userId = ref(0);
    const imageSrcPath = ref("");
    const defaultImage = ref("/media/avatars/blank.png");
    const profileDetailsValidator = Yup.object().shape({
      name: Yup.string().required().label("First name"),
    });
    const changePassword = Yup.object().shape({
      currentPassword: Yup.string().required().label("Current password"),
      newPassword: Yup.string().min(2).required().label("Password"),
      confirmPassword: Yup.string()
        .min(2)
        .required()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const removeImage = () => {
      imageSrcPath.value = defaultImage.value;
      formData.imagePath = "";
      formData.oldImagePath = "";
    };

    const onFileChange = (event) => {
      formData.imagePath = event.target.files[0];
      imageSrcPath.value = URL.createObjectURL(event.target.files[0]);
      formData.oldImagePath = "";
    };

    //Get customer details
    const getCustomerDetails = async () => {
      fullscreenLoading.value = true;
      await ApiService.query("get-current-user")
        .then((response) => {
          let userData = response.data.data;
          formData.name = userData.name;
          formData.email = userData.email;
          formData.oldImagePath = userData.image_path;
          imageSrcPath.value =
            userData.image_path == ""
              ? defaultImage.value
              : process.env.VUE_APP_API_URL +
                "../../../user/" +
                userData.image_path;
          userId.value = userData.id;
          fullscreenLoading.value = false;
        })
        .catch((error) => {
          fullscreenLoading.value = false;
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            if (status == 404) {
              router.push("/customers");
            }
          }
          //Call common error function here
        });
    };

    const saveUserInfo = () => {
      if (submitButton1.value) {
        loading.value = true;
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        let formDataSubmit = new FormData();
        formDataSubmit.append("file", formData.imagePath);
        formDataSubmit.append("old_image_path", formData.oldImagePath);
        formDataSubmit.append("name", formData.name);
        formDataSubmit.append("id", userId.value);
        ApiService.post("user-profile-update", formDataSubmit, config)
          .then((response) => {
            if (response.data) {
              loading.value = false;
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                notificationFire(message, "success");
              }
              let profileSrc = response.data.data.image_path;
              const userInfo = JSON.parse(
                JwtService.getLoggedInUserInfo() || "{}"
              );
              userInfo.image_path = profileSrc;
              localStorage.setItem("user_info", JSON.stringify(userInfo));
              emitEvent(
                "profileImg",
                profileSrc == ""
                  ? defaultImage.value
                  : process.env.VUE_APP_API_URL + "../../../user/" + profileSrc
              );
            }
          })
          .catch((error) => {
            handleError(error);
          });
      }
    };

    const handleError = (err) => {
      let response = err.response;
      if (response) {
        let status = response.status;
        let message = response.data.message;
        if (status == 404) {
          router.push({ path: "/customers" });
        }
        if (typeof message == "object") {
          let msgString = "";
          message.forEach((obj) => {
            msgString += "<p>" + obj + "</p>";
          });
          ElNotification({
            dangerouslyUseHTMLString: true,
            title: "Error",
            message: msgString,
            type: "error",
          });
        } else {
          ElNotification({ title: "Error", message: message, type: "error" });
        }
      }
      loading.value = false;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [
        { name: formatText(route.name), active: true },
      ]);
      await getCustomerDetails();
    });

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        changePasswordLoading.value = true;
        ApiService.post("users/changepassword", {
          userId: userId.value,
          oldPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
          confirmPassword: passwordData.confirmPassword,
        })
          .then(({ data }) => {
            changePasswordLoading.value = false;
            notificationFire(data.message, "success");
          })
          .catch((error) => {
            changePasswordLoading.value = false;
            let response = error.response;
            if (response != "") {
              let message = response.data.message;
              notificationFire(message, "error");
            }
          });
      }
    };

    return {
      submitButton1,
      saveUserInfo,
      profileDetailsValidator,
      formData,
      removeImage,
      constants,
      loading,
      changePasswordLoading,
      fullscreenLoading,
      updatePassword,
      updatePasswordButton,
      changePassword,
      passwordData,
      onFileChange,
      imageSrcPath,
    };
  },
});
</script>
